.pomParents {
  .container {
    width: "100%";
  }

  .row {
    background-color: #000;
    padding: 20px;
  }

  .button {
    // Our button
    text-decoration: none;
    height: 32px;

    // Primary Button
    min-width: 80px;
    background-color: #000;
    border-color: #000;
    color: #fff;

    // Basic Button
    outline: transparent;
    position: relative;
    font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system,
      BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: medium;
    border-width: 0;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    padding: 0 16px;

    .label {
      font-weight: bold;
      font-size: medium;
      height: 32px;
      line-height: 32px;
      margin: 0 4px;
      vertical-align: top;
      display: inline-block;
    }
  }
}

.addRequestContainer {
  max-width: 900px;
  max-height: 1000px;
  margin: 0px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
}

.addRequestRow {
  padding: 20px;
}

.SubmitRequestContainer {
  max-height: 1000px;
  max-width: 900px;
  padding: 10px;
}

.SubmitRequestContainerHeader {
  font-size: 18px;
}
.SubmitRequestContainerBody {
  font-size: 14px;
}

.SubmitRequestContainerBodySigniture {
  letter-spacing: 0px;
  font-family: cursive;
  font-size: 14px;
}

.SubmitRequestContainerBodyHeader {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitRequestContainerBodyText {
  padding: 6px;
  font-size: 16px;
}

.SubmitRequestContainerBodyTextProgramsDropDown {
  padding: 2px;
  font-size: 14px;
  margin: 4px;
}

.AgreeLabel {
  float: left;
  text-align: justify;
}

.AgreeCheckbox {
  float: left !important;
  margin-right: 5px !important;
}

.submitButton {
  padding: 5px;
  margin: 5px !important;
}

.cancelButton {
  padding: 5px;
  margin: 5px !important;
}

.cursiveFont {
  @import url("https://fonts.googleapis.com/css?family=Indie+Flower&display=swap");
  font-family: "Indie Flower", cursive;
  font-size: 20px;
  color: #0078d4;
}

.SectionHeader {
  color: #000 !important;

  font-size: 20px;

  padding: 10px;
}

.pomContainer {
  margin: 0px auto;
  width: "80%";
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
  color: #000;
}

.pomRow {
  background-color: #fff;
  padding: 20px;
}

.pomRowMain {
  background-color: #fff;
  padding: 20px;
  min-height: 400px;
}

.pomColumnRight {
  position: relative;
  padding: 20;
}

.pomColumnFull {
  display: flex;
  justify-content: center;
}

.pomActionLink {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  color: #0078d4;
}
