* {
  box-sizing: border-box;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    "Helvetica Neue", sans-serif;
}
a:link{
  text-decoration: none!important;
  color: inherit !important;
}

.mainDivRouter {
  flex-grow: 1;
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
}
.loadingDiv {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.loginGrid {
  justify-content: center;
  align-items: center;
  height: 65vh;
}
.loginGridDiv {
  color: #000;
  padding: 20px;
  margin: 40px;
  display: inline-block;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
}
.authenticateDiv {
  color: #000;
  padding: 40px;
  margin: 40px;
  display: inline-block;
  min-width: 200px;
  max-width: 600px;
  min-height: 200px;
}
.drawerController {
  display: flex;

  scroll-behavior: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logInButton {
  padding: 20px;
}

.logInButton:hover {
  text-decoration: underline;
  background-color: lightgray;
  padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@import url("https://fonts.googleapis.com/css?family=Comic+Neue&display=swap");

:root {
  --color-gray: #3d3c41;
  --color-gray-light: #dfdbf0;

  --color-blue: #006cb9;
  --color-blue-light: #d7efff;
  --color-blue-dark: #004e83;

  --color-yellow: #ffb819;
  --color-yellow-light: #fcf6e8;

  width: 100;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-family: "Comic Neue", cursive;
  font-size: 26px;
  color: #61dafb;
}

@import url("https://fonts.googleapis.com/css?family=Comic+Neue&display=swap");
.cursiveFont {
  font-family: "Comic Neue", cursive !important;
  font-size: 25px;
  color: #f7fafc;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.header {
  position: "relative";
  padding-left: 20px;
  /* CAPTION (18) */

  font-family: "Open Sans" !important;
  font-style: bold;
  font-weight: bold;
  font-size: 18px;
  color: var(--color-blue);

  margin-bottom: 30px;
}

.headerTitle {
  position: "relative";
  padding: 10px;
  font-family: "Open Sans" !important;
  font-style: bold;
  font-weight: bold;
  font-size: 18px;
  color: var(--color-blue);
  width: 200px;
}

.sectionTitle {
  position: "relative";
  padding: 5px;
  font-family: "Open Sans" !important;
  font-style: bold;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-blue);
}

.AliceLogo {
  position: relative;
  max-width: 284px !important;
  max-height: 91px !important;
}

.TopLeft {
  position: relative;
  max-width: 840px !important;
  max-height: 208px !important;

  /* BG - White */

  background: #ffffff;
  border-radius: 10px;
}

.leftMenuDiv {
  width: 100px;
  height: 100px;
  border: solid 1px #ccc;
  display: inline-block;
  background-color: #61dafb;
}

.rightMenuDiv {
  width: 100px;
  height: 100px;
  border: solid 1px #ccc;
  display: inline-block;
}

.content {
  margin-left: "20px";
}

.contentShift {
  margin-left: "240px";
}

.content {
  flex: 1;
  display: flex;
  overflow: auto;
}

.box {
  display: flex;
  min-height: min-content; /* needs vendor prefixes */
}

.iconClass {
  font-size: 20px;
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .drawerController {
    display: block;
    scroll-behavior: unset;
    height: 99vh;
  }
}
