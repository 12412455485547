
.ovHidden {
  display: none !important;
}

.container {
  max-width: 700px;
  margin: 0px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
  color: '#fff';
}

.pomBody {
  display: flex;
  justify-content: center;
}

.pomMainGrid {
  width: max-content;
}
.ovGrid {
  width: 100%;
  margin: auto !important;
  padding: 20px !important;
}
.ovRow {
  padding: 2px;
}

.ovColumn {
 
  padding: 5px;
}

.ovPOMColumnLeftExpanded {
  width: 340px;
  padding: "10px";
  float: left !important;
  height: 100% !important;

  padding-bottom: 50px !important;
  margin-bottom: 50px !important;

}

.ovPOMColumnLeftCollapsed {
  width: 46px;
  padding: "10px";
  float: left !important;
  height: 100% !important;

  padding-bottom: 50px !important;
  margin-bottom: 50px !important;

}

.ovPOMOrgCellExpanded {
  display: inline;
}

.ovPOMOrgCellCollapsed {
  display: none;
}

.ovPOMColumnRightExpanded {
  min-width: 1200px !important;
  width: auto !important;
margin-left:45px;
  padding-left: 10px !important;
}

.ovPOMColumnRightCollapsed {
  min-width: 1200px !important;
  width: auto !important;
  margin-left:330px;
  padding-left: 20px !important;
}

.ovColumnThird {
  padding: 20px;
  float: left !important;
}

.ovColumnRight {
  padding: 20px;
  float: left !important;
}

.ovColumnFull {
  padding: 5px;
}


.orgListCell {
  cursor: pointer;
  padding: 4px;
  font-weight: normal;
}

.orgListCell:hover {
  background-color: #cae7f8;
}

.orgListCellLogo {
  padding-top: 5px;
  padding-right: 10px;
  float: left;
  position: relative;
  height: 32px !important;
  width: 32px !important;
}

.orgListCellOrgTitle {
  padding: 5px;
  position: relative;
  white-space: nowrap;
}

.orgListCellActive {
  background-color: rgb(0, 108, 190);
  font-weight: bold;
  color: whitesmoke;
  padding: 8px;
}

.sectionHeader {
  font-size: 15px;
  height: 28px;
  padding: 8px;
}

.spinnerClass {
  height: "500px";
  position: "relative";
}

.loadingClass {
  height: 200px !important;
  align-items: center !important;
  align-content: "center";
  margin: 160px !important;
}

.ovPOMProgramsContainer {
  height: 99vh !important;
  min-width: 600px !important;
  max-width: 1300 !important;
  margin: 20px !important;
  border-radius: 8px;
}

.ovPOMProgramsHeader {
  color: blue($color: #000000);
  font-size: medium;
  padding: 10px;
}

.ovPOMProgramsBody {
  font-size: small;
  padding: 10px;
}

.ovPOMMenuPanel {
  overflow-x: hidden !important;
}

.SubmitRequestContainer {
  // max-height: 1000px;
  max-width: 900px;
  padding: 10px;
  overflow: hidden;
}
.SubmitRequestScroll{
  overflow: hidden;
}

.SubmitRequestContainerHeader {
  font-size: 18px;
}
.SubmitRequestContainerBody {
  font-size: 14px;
}

.SubmitRequestContainerBodySigniture {
  letter-spacing: 0px;
  font-family: cursive;
  font-size: 14px;
}

.SubmitRequestContainerBodyHeader {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitRequestContainerBodyText {
  padding: 6px;
  font-size: 16px;
}

.SubmitRequestContainerBodyTextProgramsDropDown {
  padding: 2px;
  font-size: 14px;
  margin: 4px;
}

.cursiveFont {
  @import url("https://fonts.googleapis.com/css?family=Indie+Flower&display=swap");
  font-family: "Indie Flower", cursive;
  font-size: 16px;
  color: #0078d4;
}

.SectionHeader {

  font-size: 20px;

  padding: 10px;
}

.scrolBarCustom {
  -webkit-overflow-scrolling: "auto";
  overflow-x: hidden !important;

 
  border-color: "#ffffff";
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    overflow-x: hidden !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: "#000";
  }
}
