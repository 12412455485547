.studentList,
.requestStudentList {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin: 0 auto;
}
.studentList th,
.requestStudentList th {
  padding-top: 12px;
  padding-bottom: 12px;

  background-color: rgb(0, 120, 212);
  color: white;
}


.studentList td,
.studentList th,
.requestStudentList td,
.requestStudentList th {
  padding: 15px;
}

.pending div, 
.approved div, 
.status div,
.retract div,
.noretract div, 
.miscHeader div {
  display: none;
}

.studentList tbody tr:hover {
  background-color: rgb(243, 242, 241);
  cursor: pointer;
}
.requestStudentList tbody tr:hover {
  background-color: rgb(243, 242, 241);
}
.studentList Thead:hover {
  border: none;
}

.pending {
  color: rgb(0, 120, 212);
  font-weight: bold;
}

.approved {
  color: rgb(49, 117, 47);
  font-weight: bold;
}

.status {
  font-weight: bold;
}

.retract {
  font-weight: bold;
  color: rgb(0, 120, 212);
  text-decoration: underline;
  cursor: pointer;
}

.retract:hover {
  cursor: pointer;
}

.noretract {
  text-decoration: none;
}

.studentListPanel {
  padding: 6px;
  margin: 6px;
  font-size: 16px;
  justify-content: center;
  line-height: 150%;
}
.noStudents{
  height: 100%;
  font-size: 16px;
  width: 100%;
  top: 0;
  position: relative;
  padding-left: 30px;
  padding-top: 0px;
}
.spinner{
  height: 200px;
  align-items: center;
  align-content: center;
  margin: 160px;
}
.footerVersion{
  font-size: 10px;
  position: relative;
  float: right;
  padding: 10px;
}