.col_flex,
.col_flex_v2 {
  flex: 1 1 49%;
  margin: 1%;
}

.textFieldMargin {
  margin: 1%;
}

.dropDownSelect {
  width: 100%;
  padding: 10px 0px;
}

.divFlex {
  display: flex;
}

.infoTable {
  width: 70%;
  margin: 1%;
  display:inline;
}

.wrapPOM {
  padding: 0;
}

@media screen and (min-width: 600px) {
  .wrapPOM {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .wrapPOM {
    padding: 0 10vw;
  }
}

.nameWarn {
  font-size: 12px;
  padding-left: 10px;
  color: red;
  position: absolute;
  margin-top: -8px;
}

.tableList {
  width:100%;
  position: relative;
  font-size: 14px;
  border-collapse: collapse !important;
  text-align: left;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.tableList Tr {
  display: table-row;
  vertical-align: middle;
  outline: 0;
}
.tableList Thead {
  display: table-header-group;
}
.tableList Tbody {
  display: table-row-group;
}
.tableList Th {
  padding-top: 12px;
  padding-bottom: 12px;

  background-color: rgb(0, 120, 212);
  color: white;
  display: table-cell;
}

.tableList Td div {
  display: none;
}

.approved {
  color: green;
}
.denied {
  color: red;
}

.pending {
  color: #0078d4;
}

.retracted {
  color: gray;
}

.tableList Tbody:nth-child(even) {
  background-color: #f2f2f2;
}
.tableList Td,
.tableList Th {
  padding: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.tableList Tbody Tr:hover {
  background-color: rgb(243, 242, 241);
  cursor: pointer;
}

.tableList Thead:hover {
  border: none;
}

.spinnerPos {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.dropdown::after {
  content: "*";
  color: rgb(164, 38, 44);
  position: absolute;
  font-size: 14px;
  top: -5px;
  right: -10px;
}
.checkBox {
  color: #0078d4 !important;
}

.checkBoxDisabled {
  color: gray !important;
}

.moreIconClass {
  font-size: 30px;
  /* padding-top: 10px; */
  margin: 5px;
}

.noPivotDisplay {
  display: none;
}
.pivotDisplay {
  display: block;
}

.moreIconClass:hover {
  background-color: #0078d4;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .dropDownSelect {
    width: 100%;
  }

  .infoTable {
    width: 98%;
    margin: 1%;
  }

  .divFlex {
    display: block;
  }
  .iconSmallScreen {
    display: none;
  }
}

